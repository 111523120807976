.phone-input {
    border: none !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
    border-radius: 0px !important;
    width: 100% !important;
    padding: 13.5px 16px 5.5px 45px !important;
  }
  
  .phone-input:focus {
    border: none !important;
    border-bottom: 2px solid #124c4d !important;
    box-shadow: none !important;
  }
  
  .phone-input-container {
    width: 100% !important;
    margin-top: -15px;
  }
  
  .phone-input-container .special-label {
    left: -6px !important;
    margin-top: -7px;
  }
  
  .phone-input:focus .special-label {
    color: #124c4d !important;
  }
  
  .phone-input-container .selected-flag {
    left: -12px !important;
  }
  
  .dropZone-wrapper {
    width: 75% !important;
  }
  
  .dropZone-wrapper div {
    width: 100% !important;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  