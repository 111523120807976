.orders-wrapper-under-sticky {
  margin-top: 10px !important;
}

.orders-wrapper {
  transition: 0ms;
  width: 76.5vw !important;
}

.sidebar {
  top: 0px;
  margin-left: -11px;
  background-color: white;
  padding-left: 12px;
}

.ordersHeaderwrapper {
  gap: 3px;
  flex-wrap: nowrap !important;
  width: 100%;
  display: flex !important;
}

.ordersHeaderElement {
  width: 100% !important;
}

.ordersUnderSticky {
  margin-top: 30px;
}

.unpaid-order {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.ordersHeaderwrapper .MuiBadge-anchorOriginTopRightRectangle {
  right: 15px !important;
  top: 18px !important;
}
.MuiBadge-anchorOriginTopRightRectangle {
  right: 30px !important;
}

.stuart-modal-dialog {
  height: 80vh !important;
  width: 80vw;
}
.orders-scroll-bar-wrapper {
  position: absolute;
  top: 95px;
  margin-left: 10px;
}
.store-filter-wrapper {
  display: flex;
  flex-direction: column;
  z-index: 1202;
  top: 21px;
  position: fixed;
}
.modal-create-menu-content{
  overflow-y: auto;
}

.button-container{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 16px;
}

.close-button{
  background-color: white;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  border: 1px solid #DDDD
}
@media (max-width: 650px) {
  .create-menu-dialog {
    width: 100vw;
    display: flex;
    justify-content: center;
  }
  .modal-create-menu-content {
    width: 100vw !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    background-color: #ffffff;
  }
  .orders-page-content-wrapper {
    margin: -12px;
    padding: 12px;
    max-width: 100vw;
    max-height: 90vh;
    overflow-y: scroll;
    overflow-x: scroll;
  }
  .store-filter-wrapper {
    top: 20px;
    margin-left: 45px;
  }
}

@media ((max-width: 900px) and (min-width:650px)) {
  .create-menu-dialog {
    width: 70vw !important;
    display: flex;
    justify-content: flex-start;
  }
  .modal-create-menu-content {
    width: 100vw !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    background-color: #ffffff;

  }
  .orders-scroll-bar-wrapper {
    position: absolute;
    top: 95px;
    margin-left: 10px;
    max-width: 77vw;
  }
}
@media (min-width: 900px) {
  .orders-scroll-bar-wrapper {
    position: absolute;
    top: 95px;
    margin-left: 10px;
    max-width: 80vw;
  }
  .modal-create-menu-content {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    width: 60vw !important;
    height: 100vh;
    padding-left: 10px;
    padding-right: 10px;
  }
  .modal-create-menu-order-content {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    width: 460px !important;
    height: 80vh;
    position: fixed;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.order-element-wrapper {
  min-width: 9vw !important;
}

@media (max-width: 650px) {
  .order-element-wrapper {
    min-width: 200px !important;
  }
}
