@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Inter !important;
  font-weight: 500;
  font-size: 16px !important;
}
p {
  font-family: Inter !important;
  size: 16px;
  font-size: 16px !important;
  line-height: 18.2px;
}
h6 {
  font-family: Inter !important;
}
@font-face {
  font-family: Inter;
  src: url(./assets/fonts/Inter-Regular.ttf);
}
select {
  -webkit-appearance: none;
  appearance: none;
}
option {
  font-family: poppiInterns !important;
}

.table-cell {
  font-family: Inter !important;
  font-size: 16px !important;
  color: #272727 !important;
}
.borderless-title {
  font-family: Inter !important;
  font-size: 16px !important;
  color: #3f3f3f !important;
  font-weight: 400 !important;
}

.table-cell-border {
  border-bottom: 1px solid #fff !important;
  font-family: Inter !important;
  padding: 10px 16px 16px 16px !important;
}

.table-cell-title {
  font-size: 16px !important;
  font-family: Inter !important;
  color: #3f3f3f !important;
  font-weight: 400 !important;
  border-bottom: 2px solid #eeeeee !important;
}

.table-cell-fix-border {
  border-bottom: 2px solid #eeeeee !important;
}

.table-cell-fix-border td {
  border-bottom: 2px solid #eeeeee !important;
}
.form-control span {
  font-family: Inter;
}

.form-control label {
  font-family: Inter;
  color: #DDD;
}

.form-control-incompleted label {
  font-family: Inter;
  color: #DDD;
}

.form-control-incompleted .MuiInputBase-root::before {
  border-bottom: 1px solid #DDD;
}

.select-wrapper {
  position: relative;
  content: "▼";
  color: white;
  font-size: 16px;
  width: 100%;
}

.select-wrapper::before {
  content: "▼";
  color: white;
  font-size: 0.7rem;
  top: 25%;
  bottom: 25%;
  right: 15px;
  position: absolute;
  width: fit-content;
}

.select-input {
  position: relative;
  content: "▼";
  color: black;
  font-size: 16px;
  width: 100%;
}

.select-input::before {
  content: "▼";
  color: black;
  font-size: 0.7rem;
  top: 25%;
  bottom: 25%;
  right: 15px;
  position: absolute;
  width: fit-content;
}

.is-sticky {
  position: fixed;
  top: 0px;
  background-color: white;
  width: 100%;
  padding: 10px 0 10px 24px;
  border-bottom: 2px solid #eeeeee;
}

.ingredients-table-container {
  width: 100%;
}

.ingredients-table-container th {
  text-align: start;
}

.ingredients-table-container td p {
  white-space: nowrap;
  margin-top: 25px;
  align-self: center;
}
.extra-th {
  width: 15vw;
}

.ingredient-th {
  width: 20vw;
}

.round-input fieldset {
  border-radius: 999px;
}
.round-input input {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 15px;
  padding-left: 15px;
}

.round-input-left fieldset {
  border-bottom-left-radius: 999px;
  border-top-left-radius: 999px;
  border-top: solid 1px #612ef24d;
  border-left: solid 1px #612ef24d;
  border-bottom: solid 1px #612ef24d;
  border-right: solid 1px transparent;
}
.round-input-left input {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 15px;
  padding-left: 15px;
  background-color: white;
  color: #612ef2;
  border-bottom-left-radius: 999px;
  border-top-left-radius: 999px;
}

.custom-datepicker-left input {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: white;
  color: #612ef2;
  border-bottom-left-radius: 999px;
  border-top-left-radius: 999px;
}

.custom-datepicker-right input {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 15px;
  padding-left: 15px;
  background-color: white;
  color: #612ef2;
  border-bottom-right-radius: 999px;
  border-top-right-radius: 999px;
}

.custom-datepicker-left fieldset {
  border-bottom-left-radius: 999px;
  border-top-left-radius: 999px;
  border: solid 1px #612ef24d;
}

.custom-datepicker-right fieldset {
  border-bottom-right-radius: 999px;
  border-top-right-radius: 999px;
  border: solid 1px #612ef24d;
}

.custom-datepicker label {
  color: #612ef2;
  position: absolute;
  top: -0.6rem;
}

.custom-datepicker .MuiSvgIcon-root {
  display: none;
}
.custom-datepicker .MuiInputAdornment-root {
  display: none;
}

.custom-timepicker .MuiSvgIcon-root {
  color: #dddddd;
  width: 1.3rem;
}

.custom-datepicker-full input {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #272727;
}

.custom-datepicker-full fieldset {
  border-radius: 999px;
}

.custom-datepicker-full label {
  font-family: Inter !important;
  margin: 0 1px;
}

.round-input-right fieldset {
  border-bottom-right-radius: 999px;
  border-top-right-radius: 999px;
  border: solid 1px #612ef24d;
}
.round-input-right input {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 15px;
  padding-left: 15px;
  background-color: white;
  color: #612ef2;
  border-bottom-right-radius: 999px;
  border-top-right-radius: 999px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #fff;
  box-shadow: inset 0 0 20px 20px rgba(0, 0, 0, 0);
}

.accordion-header span {
  display: none;
}

.normalize-select label {
  color: #aaaaaa !important;
}
.normalize-select div::before {
  border-bottom: #dddddd 1px solid !important;
}

/* .normalize-select div {
  padding-top: 1px;
} */
.custom-input label {
  font-family: Inter !important;
}

.fix-label-input {
  font-family: Inter !important;
}

.fix-label .MuiInputBase-root {
  margin-top: 8px !important;
}

.fix-label label {
  margin-left: -14px;
  color: #aaaaaa;
  font-family: Inter !important;
}

.fix-label div::before {
  border-bottom: #dddddd 1px solid !important;
}

.country-input .MuiSvgIcon-root {
  color: #000000;
}

.country-onboarding input {
  color: #fff;
}
.country-onboarding .MuiSvgIcon-root {
  color: #fff;
}

.address-border-bottom button {
  border-bottom-color: #eeeeee;
  border-bottom-width: 2px;
}

.add-padding-to-input input {
  padding-left: 22px;
}

.center-line-chart div {
  width: 95% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.switchbox {
  background-color: #7a7a7a;
  width: 300px;
  height: 30px;
  border-radius: 50px;
}

.switchbox label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  width: 300px;
  height: 30px;
  display: "flex";
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.switchbox label::before {
  border-radius: 50px;
  background: black !important;
}
.switchbox label span.on,
.switchbox label span.off {
  position: absolute;
  top: 0;
  width: 50%;
  height: 30px;
  text-align: center;
  padding-top: 3px;
}
.switchbox span.on {
  color: white;
  right: inherit;
  left: 0;
  border: 1px solid #7a7a7a;
  border-right: transparent;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
}
.switchbox span.off {
  right: 0;
  color: black;
  border: 1px solid #7a7a7a;
  border-left: transparent;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
}
.switchbox input {
  display: none;
}
.switchbox input:checked + label:before {
  color: black;
}
.switchbox input:checked + label:after {
  color: #737373;
}

label {
  position: relative;
}

.switchbox input + label:before {
  transition: 0.3s;
  content: "";
  display: block;
  width: 50%;
  height: 29px;
  position: absolute;
  left: 0;
  top: 0;
  background: #612ef2; /* W3C */
}

.switchbox input:checked + label:before {
  left: 50%;
}

.title-onboarding {
  /* font-family: Work Sans, sans-serif !important; */
  color: white;
  font-size: 35px !important;
}

.form-onboarding label {
  margin-left: -14px;
  color: #7a7a7a !important;
  font-family: Inter !important;
}

.form-onboarding input {
  color: white;
  font-family: Inter !important;
}

.form-onboarding div::before {
  border-bottom: #7a7a7a 1px solid !important;
}

.form-onboarding div {
  color: white;
}

.form-onboarding svg {
  fill: white;
}

.dropzone-onboarding p {
  color: white !important;
}

.form-onboarding-country label {
  color: #7a7a7a !important;
  font-family: Inter !important;
}
.form-onboarding-country input {
  color: white;
  font-family: Inter !important;
}

.form-onboarding-country div::before {
  /* border-bottom: #7a7a7a 0px solid !important; */
}

.form-onboarding-country div {
  border-bottom: #7a7a7a 1px solid !important;
}

.form-onboarding-country div .MuiAutocomplete-endAdornment {
  display: none;
}

.pac-container {
  box-shadow: none;
  border-top: none;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  font-family: Inter !important;
  z-index: 1301 !important;
}

.pac-container:after {
  right: 10px !important;
  margin-right: 10px;
  margin-bottom: 5px;
  font-size: 8px;
}

.form-onboarding div {
  border-bottom: #7a7a7a 1px solid !important;
}

.form-control-onboarding .MuiSvgIcon-root {
  fill: #7a7a7a;
}

.form-control-onboarding span {
  color: white;
}

.fix-mui-table .MuiTableCell-root {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.remove-padding {
  padding-right: 0 !important;
}

.select-type-store-modal ul {
  max-height: 160px !important;
}
